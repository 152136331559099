import React, { createContext, useState, useEffect, ReactNode, useRef } from 'react';
import { getSessions, createChatSession, getSessionMessages } from './api';
import WebSocketClient from './websocket';
import { Message, Session } from './types';

const CHAT_BOT_URL = process.env.REACT_APP_CHAT_BOT_URL || 'https://a.datagram.ai';
const CHAT_BOT_DOMAIN = (new URL(CHAT_BOT_URL)).hostname;

interface ChatContextType {
  sessions: Session[];
  currentSession: Session | null;
  messages: Message[];
  startNewSession: (message: string) => Session | undefined;
  setCurrentSession: (session: Session) => void;
  ws: any;
  addUserMessage: (message: string) => void;
  getWS: () => WebSocketClient | null;
  messageLoading: boolean;
  setSessions: (sessions: Session[]) => void;
  setMessages: (messages: Message[]) => void;
  sessionLoading: boolean;
}

const defaultContext: ChatContextType = {
  sessions: [],
  currentSession: null,
  messages: [],
  startNewSession: () => undefined,
  setCurrentSession: () => {},
  ws: null,
  addUserMessage: () => {},
  getWS: () => null,
  messageLoading: false,
  setSessions: () => {},
  setMessages: () => {},
  sessionLoading: false,
};

export const ChatContext = createContext<ChatContextType>(defaultContext);

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [currentSession, setCurrentSession] = useState<Session | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [fetchSession , setFetchSession] = useState<boolean>(false);
  const [messageLoading, setMessageLoading] = useState<boolean>(false);
  const [sessionLoading, setSessionLoading] = useState<boolean>(false);


  const ws = useRef<WebSocketClient | null>(null)

  const addUserMessage = (message: string) => {
    const newMessage = {
      id: Date.now().toString(),
      session_id: currentSession?.session_id,
      sender: "human",
      text: message,
    } as Message;
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  }

  useEffect(() => {
    // Load sessions on mount
    const fetchSessions = async () => {
      const data = await getSessions();
      setSessions(data);
      if (data.length > 0) {
        setCurrentSession(data[0]);
      }
    };
    setSessionLoading(true);
    fetchSessions();
    setSessionLoading(false);
  }, [fetchSession]);

  useEffect(() => {
    if (currentSession) {
      if (currentSession.session_id === 'new') {
        setMessages([]);
      }
      else {
      // Fetch messages when current session changes
      const fetchMessages = async () => {
        try {
          setMessageLoading(true);
          const data = await getSessionMessages(currentSession.session_id);
          setMessages(data);
          setMessageLoading(false);
        }
        catch (error) {
          console.error('Error fetching messages:', error);
          setMessages([]);
        }
      };
      fetchMessages();

      // Connect WebSocket and listen for messages
      const protocal = CHAT_BOT_URL.includes('https:') ? 'wss' : 'ws';
      const wsClient = new WebSocketClient(`${protocal}://${CHAT_BOT_DOMAIN}/ws/llm?session_id=` + currentSession?.session_id);
      wsClient.connect();
      wsClient.on("default", (message: Message) => {
        const newMessage = {
          id: new Date().toISOString(),
          session_id: currentSession?.session_id,
          sender: "bot",
          text: message.output,
        } as Message;
        if (messages.length === 1){
          setFetchSession(true);
        }
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });
      ws.current = wsClient;
    }
    }
  }, [currentSession]);

  useEffect(() => {
    if (!currentSession || currentSession.session_id === 'new') {
      return;
    }
    // Connect WebSocket and listen for messages
    const protocal = CHAT_BOT_URL.includes('https:') ? 'wss' : 'ws';
    const wsClient = new WebSocketClient(`${protocal}://${CHAT_BOT_DOMAIN}/ws/llm?session_id=` + currentSession?.session_id);
    wsClient.connect();
    wsClient.on("default", (message: Message) => {
      const newMessage = {
        id: new Date().toISOString(),
        session_id: currentSession?.session_id,
        sender: "bot",
        text: message.output,
      } as Message;
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
    ws.current = wsClient
  }, [currentSession]);

  const startNewSession = async (message: string) => {
    const newSession = await createChatSession(message);
    if (newSession) {
      const newSessions  =  sessions.filter((session) => session.session_id !== 'new');
      setSessions([newSession, ...newSessions,]);
      setCurrentSession(newSession);
      return newSession;
    }
  };

  const getWS = async () => {
    if (ws.current){
      return ws.current;
    }
    if (currentSession && currentSession.session_id !== 'new') {
      const protocal = CHAT_BOT_URL.includes('https:') ? 'wss' : 'ws';
      const wsClient = new WebSocketClient(`${protocal}://${CHAT_BOT_DOMAIN}/ws/llm?session_id=` + currentSession?.session_id);
      wsClient.connect();
      wsClient.on("default", (message: Message) => {
        const newMessage = {
          id: new Date().toISOString(),
          session_id: currentSession?.session_id,
          sender: "bot",
          text: message.output,
        } as Message;
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });
      ws.current = wsClient;
      return wsClient;
    }
    else {
      return null;
    }
  }

  return (
    <ChatContext.Provider
      value={{
        sessions,
        currentSession,
        messages,
        startNewSession,
        messageLoading,
        setCurrentSession,
        ws,
        addUserMessage,
        getWS,
        setSessions,
        setMessages,
        sessionLoading
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
