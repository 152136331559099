import React, { useState, useContext } from 'react';
import { ChatContext } from './ChatContext';
import SendIcon from '@mui/icons-material/Send';
import { Button, TextField } from '@mui/material';
import { Session } from './types';
import "./chat.scss";

const token = localStorage.getItem("datagramToken");

const MessageInput: React.FC = () => {
  const [input, setInput] = useState('');
  const { currentSession, getWS, addUserMessage , setMessages, startNewSession,} = useContext(ChatContext);

  let newSession: Session | undefined = undefined;

  const handleSend = async () => {
    if (input.trim()) {
      if (currentSession) {
        if (currentSession.session_id === 'new') {
          setMessages([{
            id: Date.now().toString(),
            session_id: 'new',
            sender: 'human',
            text: input,
            timestamp: new Date().getTime(),
          }])
          setInput('');
          newSession = startNewSession(input);
          return;
        }
      }
      const activeSession = newSession ? newSession : currentSession;
      const ws = await getWS();
      if (activeSession && ws) {
        ws.send({ session_id: activeSession.session_id, messages: input , "token": token});
        addUserMessage(input);
        setInput('');
      } else {
        console.error('No active session.');
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  }

  return (
    <div className="p-4 w-full bg-white border-t">
      <div className="flex items-center">
      <div className='inputWithButton'>
        <TextField
          fullWidth
          value={input}
          onKeyDown={handleKeyDown}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask me anything..."
        />

        <Button
          onClick={handleSend}
          variant="text"
          className="ml-2"
          endIcon={<SendIcon />}
        >
        </Button>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
